<template>
  <div class="public-ticket-container">
    <div v-if="ticket"
         class="ticket-public o-card">
      <div class="ticket-main">
        <div class="ticket-title-container grid-wrapper grid-wrapper--top">
          <h2 class="ticket-title">
            {{ ticket.title }}
          </h2>
        </div>
        <div class="status-container grid-wrapper">
          <TicketStatus :state="ticket.status"
                        @input="updateStatus" />
          <q-btn label="Copier le lien"
                  class="copy-link"
                  size="sm"
                  outline
                  @click="copyLink"
                />
        </div>
        <div class="ticket-infos grid-wrapper">
          <div class="grid-tile">
            <label class="grid-tile__label">{{$t('pages.ticket.ticketInfo.date')}}</label>
            <span class="grid-tile__value">
              {{ formatDate(ticket.dateCreation) }}
            </span>
          </div>
          <div class="grid-tile">
            <label class="grid-tile__label">{{$t('pages.ticket.ticketInfo.author')}}</label>
            <span class="grid-tile__value">
              {{ ticket.author.firstName }} {{ ticket.author.lastName }}
            </span>
            <span class="grid-tile__value">
              {{ ticket.author.email }}
            </span>
          </div>
          <div class="grid-tile">
            <label class="grid-tile__label">{{$t('pages.ticket.creationEdition.labels.type')}}</label>
            <span class="grid-tile__value">
              {{ ticket.type }}
            </span>
          </div>
          <div class="grid-tile">
            <label class="grid-tile__label">{{$t('pages.ticket.creationEdition.labels.priority')}}</label>
            <span class="grid-tile__value">
              {{ ticket.priority }}
            </span>
          </div>
        </div>
        <div class="message-container grid-wrapper">
          <label class="grid-tile__label">{{$t('pages.ticket.creationEdition.labels.message')}}</label>
          <textarea v-model="ticket.message"
                    class="form-textarea"
                    rows="8"
                    readonly />
          </div>
      <div class="comments-container grid-wrapper">
      <TicketComments :comments="ticket.comments"
                      :ticketShortId="shortId"
                      class="comment-list"
                      @refresh="fetchComments"/>
      </div>
    </div>
        <div class="side-container">
          <div class="map-container grid-wrapper">
            <label class="grid-tile__label">{{$t('pages.ticket.creationEdition.labels.map')}}</label>
              <TicketMap v-if="ticket.floor"
                         id="buildingContainer"
                         :ticket="ticket"/>
              <Building3D v-else
                          :buildingShape="ticket.building.concave_hull"
                          :preferences="ticket.building.preferences" />
          </div>
          <div class="grid-wrapper">
            <div class="grid-tile">
              <label class="grid-tile__label">{{$t('pages.ticket.creationEdition.labels.building')}}</label>
              <span class="grid-tile__value">{{ ticket.building.name }}</span>
              <span class="grid-tile__value">{{ ticket.building.address.line1 }}</span>
              <span class="grid-tile__value">{{ ticket.building.address.zipCode }} {{ ticket.building.address.city }}</span>
              <span class="grid-tile__value">{{ ticket.building.address.country }}</span>
            </div>
            <div v-if="ticket.floor"
                 class="grid-tile">
              <label class="grid-tile__label">{{$t('pages.ticket.ticketInfo.location')}}</label>
              <span class="grid-tile__value">{{ ticket.floor.name }} (Level {{ ticket.floor.level }})</span>
              <span v-if="ticket.space"
                    class="grid-tile__value">{{ticket.space.name}}</span>
            </div>
          </div>

      <div class="documents-container grid-wrapper">
        <label class="grid-tile__label">{{$t('pages.ticket.creationEdition.labels.documents')}}</label>
        <div class="files-list">
          <span v-for="(file, idx) in ticket.documents"
                :key="idx"
                class="file-item file-item--download"
                @click="downloadFile(file)">
            {{ file.filename }}
          </span>
          <span v-if="ticket.documents && ticket.documents.length <= 0"
                class="form-value">
            {{$t('pages.ticket.creationEdition.messages.noDocuments')}}
          </span>
          <input id="file"
                 ref="files"
                 type="file"
                 accept="image/*"
                 class="input-files"
                 @change="uploadFile">
          <label for="file">
            <PlusPicto size="20px"
                       class="picto"
                       color="#827f72" />
            {{$t('pages.ticket.creationEdition.labels.addDocument')}}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import api from 'api';

import PlusPicto from '@/app/components/ui/pictos/plus.vue';
import TicketComments from '@/app/pages/ticket/components/ticket-comments.vue';
import TicketStatus from '@/app/pages/ticket/components/ticket-status.vue';
import TicketMap from '@/app/pages/ticket/components/ticket-map.vue';
import Building3D from '@/app/components/buildings/building3d.vue';

export default {
  name: 'PublicTicket',
  components: { PlusPicto, TicketComments, TicketStatus, TicketMap, Building3D },
  props: {
    shortId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      intervalId: undefined,
      showMapModal: false,
    };
  },
  computed: {
    ticket() {
      return this.$store.state.tickets.collection.find(ticket => ticket.shortId === this.shortId);
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  async mounted() {
    this.refreshTicket();
    await this.$store.dispatch('tickets/readPublic', this.shortId);
    this.intervalId = setInterval(() => this.$store.dispatch('tickets/readPublic', this.shortId), 3000);
  },
  methods: {
    async copyLink() {
      const url = `${location.protocol}//${location.host}/ticket/${this.ticket.shortId}`;
      try {
        await this.$copyText(url);
        this.$q.notify({
          message: this.$t('pages.ticket.toasts.linkPastedClipboard'),
          type: 'positive',
          position: 'bottom-left',
        });
      } catch (error) {
        this.$q.notify({
          message: error.response.data || this.$t('pages.ticket.toasts.errorPastingClipboard'),
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },

    refreshTicket() {},
    toggleMapModal() {
      this.showMapModal = !this.showMapModal;
    },
    b64DecodeUnicode(str) {
      return decodeURIComponent(
        atob(str)
          .split('')
          .map(function(c) {
            return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
          })
          .join('')
      );
    },
    formatDate(date) {
      return moment(date)
        .format('DD/MM/YYYY - HH[h]mm')
        .toString();
    },
    async fetchComments() {
      const { data: comments } = await api.tickets.public.comments.fetch(this.shortId);
      this.ticket.comments = comments;
    },
    async downloadFile(file) {
      const { filename: name, mimetype: type } = file;
      try {
        const response = await api.tickets.public.file.read(this.shortId, file.shortId);
        const blob = new Blob([response.data], { type: type });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
      } catch (error) {
        this.$q.notify({
          message: error.response.data || 'Cannot download file',
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
    async uploadFile(file) {
      const files = this.$refs.files.files;
      try {
        if (files.length) {
          const formData = new FormData();
          formData.append(`files[0]`, files[0]);
          await api.tickets.public.file.create(this.shortId, formData);
          this.$q.notify({
            message: `file uploaded: ${files[0].name}`,
            type: 'positive',
            position: 'bottom-right',
          });
          await this.$store.dispatch('tickets/readPublic', this.shortId);
        }
      } catch (error) {
        this.$q.notify({
          message: error.response.data || 'Error when adding document',
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
    async updateStatus(status) {
      try {
        await this.$store.dispatch('tickets/updatePublic', { shortId: this.shortId, status });
      } catch (error) {
        this.$q.notify({
          message: error.response.data || 'Error updating ticket status',
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.ticket-public
  display flex
  margin auto
  max-width 1000px
  background-color white
  .grid-tile__label
    margin-bottom $space-1
  .ticket-main
    display flex
    flex 2
    flex-direction column
    border-right $grid-border
    .ticket-title-container
      padding $space-2
      .ticket-title
        font-size $fs-h1
    .status-container
      padding $space-2
    .ticket-infos
      flex-wrap wrap
    .message-container
      flex-direction column
      padding $space-2
      .form-textarea
        width 100%
        outline none
        border none
        font-size $fs-h2
        resize none
    .comments-container
      padding $space-2
      .comment-list
        width 100%
  .side-container
    display flex
    flex 1
    flex-direction column
    height 100%
    .map-container
      padding $space-2
      width 100%
      height 355px
    .form-value
      padding $space-2
      padding-top 0

.documents-container
  flex-direction column
  padding $space-2
  text-align center
  .grid-tile__label
    text-align left
  .files-list
    display flex
    flex-direction column
    padding 8px
    .file-item
      position relative
      display flex
      flex-direction column
      justify-content center
      align-items center
      margin-bottom 8px
      padding 16px
      border 1px solid lighten($grey, 70%)
      border-radius 5px
      box-shadow $shadow-elevation-1
      color darken($grey, 30%)
      text-transform uppercase
      letter-spacing 0.5px
      font-weight 700
      font-size 0.9rem
      transition box-shadow 0.3s ease
      &--download
        cursor pointer
        &:hover
          box-shadow $shadow-elevation-2
  .input-files
    position absolute
    z-index -1
    overflow hidden
    width 0.1px
    height 0.1px
    opacity 0
  .input-files + label
    display flex
    flex-direction column
    justify-content center
    align-items center
    padding $space-2
    border 1px solid black
    border-radius $border-radius-smooth
    box-shadow $shadow-elevation-1
    text-transform uppercase
    letter-spacing 0.5px
    font-weight 700
    font-size 0.9rem
    cursor pointer
    transition all 100ms ease
    .picto
      margin-bottom 8px
    &:hover
      box-shadow $shadow-elevation-2
    &:focus
      outline 1px dotted #000
      outline -webkit-focus-ring-color auto 5px
    .inputfile + label *
      pointer-events none

.floor-container
  width 100%
  height 100%
  text-align center
  transition:
    top 1s cubic-bezier(0.02, 0.63, 0.31, 1.04),
    left 1s cubic-bezier(0.02, 0.63, 0.31, 1.04),
    z-index 0.2s cubic-bezier(0.02, 0.63, 0.31, 1.04)
  &.active
    svg
      pointer-events all
  &.drag
    svg
      cursor move
  &--zoomed
    svg
      .space-name
        display initial !important
        transition all 100ms ease
        user-select none
  & >>> svg
    overflow visible !important
    margin auto
    transition transform 0.2s ease
    @media screen and (max-width: 500px)
      transition transform 0.1s linear
    .space
      transition fill 0.2s ease
      fill $silver
      stroke $dark
      stroke-width 0.2
      &.selected
        opacity 1
        fill white !important
    .wall
      pointer-events none
      fill #333
      stroke-width 0
    .door
      fill $silver
      stroke black
      stroke-width 0.5px
    .window
      fill darken($light, 5%)
      stroke @fill

@media screen and (max-width: 500px)
  .ticket-public
    flex-direction column
    max-width 100%
.copy-link
  margin-left auto

</style>
