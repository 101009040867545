<template>
  <div class="ticket-map"
    @click="toggleFullScreen"
    @wheel="onMouseWheel">
    <Floor2DMap id="ticket-map"
      class="ticket-map"
      :floorBase64Svg="floor2dMapFloorBase64Svg"
      :highlightRooms="floor2dMapHighlightRooms"
      :highlightPoints="floor2dMapHighlightPoints"
      :class="classMap"
      :style="style"
    />
  </div>
</template>

<script>
import _pick from 'lodash/pick';
import _get from 'lodash/get';

import Floor2DMap from '@/app/components/floors/Floor2DMap.vue';

export default {
  name: 'TicketMap',
  components: {
    Floor2DMap
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    classMap: 'small',
    style: {
      width: '100%',
      height: '100%',
    },
  }),
  computed: {
    floor2dMapFloorBase64Svg() {
      return _get(this.ticket, 'floor.svg');
    },
    floor2dMapHighlightRooms() {
      if (!this.ticket.space || !this.ticket.space.uuid) {
        return null;
      }
      return [this.ticket.space.uuid];
    },
    floor2dMapHighlightPoints() {
      if (typeof this.ticket.x !== 'number' || typeof this.ticket.y !== 'number') {
        return null;
      }
      return [_pick(this.ticket, ['x', 'y'])];
    }
  },
  methods: {
    makePlanSmall() {
      this.classMap = 'small';
      this.style = {
        width: '100%',
        height: '100%',
      };
    },
    toggleFullScreen(event) {
      if (this.classMap === 'small') {
        this.classMap = 'full-screen';
        return;
      }
      // means that click is outside of svg components
      if (event.target.tagName === 'svg') {
        this.makePlanSmall();
        return;
      }
      if (this.classMap === 'full-screen') {
        let newSize = parseInt(this.style.width);
        if (newSize < 150) newSize = 200;
        else newSize = 100;
        this.style = {
          width: `${newSize}%`,
          height: `${newSize}%`,
        };
      }
    },
    onMouseWheel(event) {
      if (this.classMap === 'full-screen') {
        if (event.target.tagName !== 'svg') {
          const direction = event.deltaY > 0 ? -10 : 10;
          let newSize = parseInt(this.style.width) + direction;
          if(newSize < 100) newSize = 100;
          this.style.width = `${newSize}%`;
          this.style.height = `${newSize}%`;
        }
      }
    },
  },
};

</script>
<style lang="stylus" scoped>

.full-screen
  position absolute
  width 100%
  height 100%
  z-index 1
  top 50%
  left 50%
  transform translate(-50%, -50%)
  overflow-y hidden
  cursor move
  transition none
</style>
